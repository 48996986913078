import styled from "styled-components";

export const HtmlContentWrapper = styled.div`
img {
  cursor: pointer;
}

::after {
  content: '';
  display: block;
  clear: both;
}
`;
